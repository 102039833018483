"use client";
import React, { useRef } from "react";
import styles from "styles/RightSection.module.css";
import chatgptlogo from "assets/chatgptlogo.png";
import chatgptlogo2 from "assets/chatgptlogo2.png";
import nouserlogo from "assets/nouserlogo.png";
import ulaiLogo from "assets/ulaiLogo.svg";
import {
  SendOutlined,
  PlayArrow,
  InfoOutlined,
  CheckCircleOutlined,
  ContentCopy,
  LogoutSharp,
  CloseFullscreen,
} from "@mui/icons-material";
import { format } from "sql-formatter";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { GlobalContext } from "context/GlobalContext";
import BottomDragableArea from "./BottomDragableArea";
import { useTheme } from "@mui/material/styles";

const RightSection = () => {
  const theme = useTheme();
  const { hitAxios, userData, setUserData, data, setData } =
    React.useContext(GlobalContext);
  const [userQuery, setUserQuery] = React.useState("");
  const [isPreviewOpen, setIsPreviewOpen] = React.useState(false);
  const [previewData, setPreviewData] = React.useState([]);
  const sendMessage = async () => {
    const currentQuery = userQuery;
    setUserQuery("");
    setUserData({
      ...userData,
      activeConversation: [
        ...(userData?.activeConversation ?? []),
        {
          _id: "new session message " + (userData?.activeConversation + 1),
          sessionId: "3",
          sender: "user",
          message: currentQuery,
          createdAt: new Date().toDateString(),
          updatedAt: new Date().toDateString(),
          __v: 0,
        },
        {
          sender: "bot",
          query: "...",
          data: [],
          createdAt: new Date().toDateString(),
          updatedAt: new Date().toDateString(),
        },
      ],
    });
    const res = await hitAxios({
      path: "/db-chat/chat/query",
      requestType: "post",
      reqObj: {
        sessionId: userData?.activeSession?.id,
        userQuery: currentQuery,
      },
    });

    if (res.status === 200) {
      if (
        res?.data?.messages === "Something went wrong!Please try that again" ||
        res?.data?.query === null ||
        res?.data?.data === null
      ) {
        setData({
          ...data,
          snackbarMessage: "Unable to get expected response.",
          snackbarOpen: true,
          severity: "error",
        });
        setUserData({
          ...userData,
          activeConversation: [
            ...(userData?.activeConversation ?? []),
            {
              _id: "new session message " + (userData?.activeConversation + 1),
              sessionId: "3",
              sender: "user",
              message: currentQuery,
              createdAt: new Date().toDateString(),
              updatedAt: new Date().toDateString(),
              __v: 0,
            },
            {
              sender: "bot",
              message: "I can't find anything meaningful in schema.",
              query: "",
              data: [],
              createdAt: new Date().toDateString(),
              updatedAt: new Date().toDateString(),
            },
          ],
        });
      } else {
        // keep
        setUserData({
          ...userData,
          activeConversation: [
            ...(userData?.activeConversation ?? []),
            {
              _id: "new session message " + (userData?.activeConversation + 1),
              sessionId: userData?.activeSession?.id,
              sender: "user",
              message: userQuery,
              createdAt: new Date().toDateString(),
              updatedAt: new Date().toDateString(),
              __v: 0,
            },
            res.data,
          ],
        });
      }
      // scrollChatToBottom();
    } else {
      setData({
        ...data,
        snackbarMessage: "Contact Admin, Open ai Query not working",
        snackbarOpen: true,
        severity: "error",
      });
      setUserData({
        ...userData,
        activeConversation: [
          ...(userData?.activeConversation ?? []),
          {
            _id: "new session message " + (userData?.activeConversation + 1),
            sessionId: "3",
            sender: "user",
            message: currentQuery,
            createdAt: new Date().toDateString(),
            updatedAt: new Date().toDateString(),
            __v: 0,
          },
          {
            sender: "bot",
            message: "Looks like, I am unable to get till my server.",
            query: "",
            data: [],
            createdAt: new Date().toDateString(),
            updatedAt: new Date().toDateString(),
          },
        ],
      });
      scrollChatToBottom();
    }
  };

  const addNewSession = async () => {
    // here we need to add one more loader in chat connections
    const res = await hitAxios({
      path: "/db-chat/chat/createsession",
      requestType: "post",
      reqObj: {
        connectionId: userData?.activeConnection["connection-id"],
      },
    });
    if (res.status === 200 && res?.data?.message === "New session created") {
      setData({
        ...data,
        snackbarMessage: "Session Created",
        snackbarOpen: true,
        severity: "success",
      });
      const sessionObj = { id: res?.data?.sessionId, active: true };
      setUserData({
        ...userData,
        allSessions: userData?.allSessions?.length
          ? [
              sessionObj,
              ...(userData?.allSessions?.map((i) => ({
                id: i?.id,
                active: false,
              })) ?? []),
            ]
          : [sessionObj],
        activeSession: sessionObj,
        activateChat: true,
      });
    } else {
      setData({
        ...data,
        snackbarMessage: "Error in creating session",
        snackbarOpen: true,
        severity: "error",
      });
    }
  };

  const elmmentScroll = useRef(null);
  const scrollChatToBottom = () => {
    elmmentScroll?.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  return (
    <Grid className={styles.rightSection}>
      <div className={styles.chatgptversion}>
        Powered by OpenAi
        <span
          style={{
            padding: "2px",
            background: "rgb(0 0 0 / 80%)",
            padding: theme.typography.pxToRem(3),
            marginLeft: theme.typography.pxToRem(4),
            borderRadius: theme.typography.pxToRem(5),
          }}
        >
          GPT-4o
        </span>
      </div>
      {userData?.activeConversation?.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            height: "82%",
            justifyContent: "flex-end",
            flexDirection: "column",
          }}
        >
          <div id="scrollableDiv" className={styles.messages}>
            {userData?.activeConversation?.map((msg, index) =>
              msg.sender === "user" ? (
                <div key={index} className={styles.messageUser}>
                  <div className={styles.details}>
                    <p>
                      <Box
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                          gap: theme.typography.pxToRem(30),
                        }}
                      >
                        <Typography variant="h5">You</Typography>
                        <Typography
                          style={{
                            fontStyle: "italic",
                            fontSize: "x-small",
                            display: "contents",
                          }}
                        >
                          {msg?.createdAt
                            ? new Date(msg?.createdAt)?.toLocaleDateString(
                                "en-us",
                                {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                  second: "numeric",
                                  minute: "numeric",
                                  hour: "numeric",
                                }
                              )
                            : ""}
                        </Typography>
                      </Box>
                      <hr />
                    </p>
                    <p>{msg?.message}</p>
                  </div>
                  <img src={nouserlogo} width={50} height={50} alt="" />
                </div>
              ) : (
                <div key={index} className={styles.messageBot}>
                  <img src={chatgptlogo2} width={50} height={50} alt="" />
                  <div className={styles.details}>
                    <p>
                      <Box
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                          gap: theme.typography.pxToRem(30),
                        }}
                      >
                        <Typography variant="h5">Chat-DB Bot</Typography>
                        <Typography
                          style={{
                            fontStyle: "italic",
                            fontSize: "x-small",
                            display: "contents",
                          }}
                        >
                          {msg?.createdAt
                            ? new Date(msg?.createdAt)?.toLocaleDateString(
                                "en-us",
                                {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                  second: "numeric",
                                  minute: "numeric",
                                  hour: "numeric",
                                }
                              )
                            : ""}
                        </Typography>
                      </Box>
                      <hr />
                    </p>
                    <div>
                      {msg?.query === "..." ? (
                        <div className="loader"></div>
                      ) : (
                        <>
                          <Typography variant="h6">{msg?.message}</Typography>
                          <div style={{ gap: 0 }}>
                            {msg?.query ? (
                              <>
                                <Box
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    backgroundColor: "rgb(40, 44, 52)",
                                    borderRadius: "4px 4px 0 0",
                                    borderBottom: "1px solid #c3c3c3",
                                    alignItems: "center",
                                    paddingLeft: "0.6em",
                                  }}
                                >
                                  <Typography variant="h6">Query</Typography>
                                  <Box
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "flex-end",
                                      backgroundColor: "rgb(40, 44, 52)",
                                      borderRadius: "4px 4px 0 0",
                                    }}
                                  >
                                    <IconButton
                                      onClick={() => {
                                        setIsPreviewOpen(!isPreviewOpen);
                                        setPreviewData(msg?.data);
                                      }}
                                    >
                                      {isPreviewOpen ? (
                                        <CloseFullscreen />
                                      ) : (
                                        <PlayArrow />
                                      )}
                                    </IconButton>

                                    <IconButton
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          msg?.query ?? ""
                                        );
                                      }}
                                    >
                                      <ContentCopy />
                                    </IconButton>
                                  </Box>
                                </Box>
                                <SyntaxHighlighter
                                  language="javascript"
                                  style={oneDark}
                                  customStyle={{
                                    borderRadius: "0 0 0.3em 0.3em",
                                    margin: "0px",
                                    borderRadius: "0 0 4px 4px",
                                    padding: "0.2em 1em 1em 1em",
                                  }}
                                >
                                  {format(msg?.query)}
                                </SyntaxHighlighter>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )
            )}
            <div ref={elmmentScroll}></div>
          </div>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "82%",
            color: "primary.main",
            opacity: "0.8",
          }}
        >
          <img src={ulaiLogo} alt="ULAI" height={150} width={150} />
          <Box display="flex">
            <img src={chatgptlogo} alt="chatgpt" height={70} width={70} />
            <Typography variant="h2">How can I help you today?</Typography>
          </Box>
          <Card
            sx={{
              p: 2,
              marginTop: theme.typography.pxToRem(30),
              display: "grid",
              gap: theme.typography.pxToRem(5),
            }}
          >
            <Typography
              variant="h6"
              sx={{ display: "flex", alignItems: "center" }}
            >
              {userData?.activeConnection ? (
                <CheckCircleOutlined sx={{ padding: "2px" }} />
              ) : (
                <InfoOutlined sx={{ padding: "2px" }} />
              )}
              Add a connection using{" "}
              <span
                style={{
                  background: "rgb(0 0 0 / 80%)",
                  padding: theme.typography.pxToRem(3),
                  marginLeft: theme.typography.pxToRem(4),
                  borderRadius: theme.typography.pxToRem(5),
                }}
              >
                Recent Connections
              </span>
              .
            </Typography>

            <Typography
              variant="h6"
              sx={{ display: "flex", alignItems: "center" }}
            >
              {userData?.activeSession ? (
                <CheckCircleOutlined sx={{ padding: "2px" }} />
              ) : (
                <InfoOutlined sx={{ padding: "2px" }} />
              )}
              Start chat with{" "}
              <span
                style={{
                  padding: "2px",
                  background: "rgb(0 0 0 / 80%)",
                  padding: theme.typography.pxToRem(3),
                  marginLeft: theme.typography.pxToRem(4),
                  borderRadius: theme.typography.pxToRem(5),
                }}
              >
                Creating Session
              </span>
              .
            </Typography>
          </Card>
        </Box>
      )}

      <div className={styles.bottomsection}>
        <div className={styles.messagebar}>
          <OutlinedInput
            id="outlined-adornment-weight"
            fullWidth
            size="small"
            disabled={!userData?.activeConnection}
            value={userQuery}
            onClick={() => {
              if (
                userData?.activeConnection &&
                userData?.activeSession === undefined
              ) {
                addNewSession();
              } else if (!userData?.activeConnection) {
                setData({
                  ...data,
                  snackbarMessage: "Create connection to start session",
                  snackbarOpen: true,
                  severity: "error",
                });
              }
            }}
            onChange={(e) => setUserQuery(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && sendMessage()}
            endAdornment={
              <SendOutlined
                onClick={() => {
                  if (
                    userQuery &&
                    userData.activeConnection &&
                    userData.activeSession
                  )
                    sendMessage();
                }}
              />
            }
            sx={{ border: "1px solid white", borderRadius: "8px" }}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
          />
        </div>
      </div>

      <BottomDragableArea
        previewData={previewData}
        isPreviewOpen={isPreviewOpen}
        setIsPreviewOpen={setIsPreviewOpen}
      />
      {/* </div> */}
    </Grid>
  );
};

export default RightSection;
