import React from "react";
import {
  AddCircleOutlined,
  DataObjectOutlined,
  ExpandMore,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { GlobalContext } from "context/GlobalContext";

const SelectedSessions = ({
  sessionExpanded,
  setSessionExpanded,
  connectionsExpanded,
}) => {
  const { hitAxios, userData, setUserData, data, setData } =
    React.useContext(GlobalContext);

  const addNewSession = async () => {
    // here we need to add one more loader in chat connections
    const res = await hitAxios({
      path: "/db-chat/chat/createsession",
      requestType: "post",
      reqObj: {
        connectionId: userData?.activeConnection["connection-id"],
      },
    });
    if (res.status === 200) {
      const sessionObj = { id: res?.data?.sessionId, active: true };
      setUserData({
        ...userData,
        allSessions: userData?.allSessions?.length
          ? [
              sessionObj,
              ...userData?.allSessions?.map((i) => ({
                id: i?.id,
                active: false,
              })),
            ]
          : [sessionObj],
        activeSession: sessionObj,
        activateChat: true,
        activeConversation: res.data.messages,
      });
    }
  };
  const changeSession = async (obj) => {
    // here we need to add one more loader in chat connections
    const res = await hitAxios({
      path: "/db-chat/messages/getsessionmessages",
      requestType: "post",
      reqObj: {
        sessionId: obj.id,
      },
    });
    if (res.status === 200) {
      setUserData({
        ...userData,
        allSessions: [
          ...userData?.allSessions?.map((i) => ({
            id: i?.id,
            active: i === obj ?? false,
          })),
        ],
        activeSession: obj,
        activateChat: true,
        activeConversation: res.data.messages,
      });
    }
  };
  return (
    <Accordion
      disableGutters
      sx={{
        backgroundColor: "rgb(42 44 44)",
        color: "white",
        "& .MuiAccordion-root .Mui-expanded": { margin: "10px 0" },
      }}
      expanded={sessionExpanded}
    >
      <AccordionSummary
        aria-controls="panel1-content"
        id="panel1-header"
        expandIcon={
          <ExpandMore
            color="primary"
            onClick={() => {
              setSessionExpanded(!sessionExpanded);
            }}
          />
        }
      >
        <Typography sx={{ width: "88%", flexShrink: 0 }}>
          Select Session
        </Typography>
        <AddCircleOutlined
          onClick={() => {
            if (userData?.activeConnection) {
              addNewSession();
            } else {
              setData({
                ...data,
                snackbarMessage: "Create connection to start session",
                snackbarOpen: true,
                severity: "error",
              });
            }
          }}
        />
      </AccordionSummary>
      <AccordionDetails
        sx={{
          overflowY: "auto",
          height: connectionsExpanded ? "32vh" : "64vh",
        }}
      >
        {userData?.allSessions?.length ? (
          userData?.allSessions?.map((obj, idx) => (
            <Button
              onClick={() => changeSession(obj)}
              sx={{
                justifyContent: "flex-start",
                backgroundColor: obj.active
                  ? "rgba(255, 255, 255, 0.5)"
                  : "none",
                ":hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                },
              }}
              fullWidth
            >
              <DataObjectOutlined color="primary" />
              <Typography
                color="primary"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textTransform: "None",
                  paddingLeft: (theme) => theme.typography.pxToRem(4),
                }}
              >
                Session {userData?.allSessions?.length - idx}
              </Typography>
            </Button>
          ))
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography>No Active Sessions</Typography>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default SelectedSessions;
