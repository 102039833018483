import React, { memo, useRef } from "react";
import ulaiLogo from "assets/ulaiLogo.svg";
import styles from "styles/LeftSection.module.css";
import { FormControl, Select, MenuItem, Typography, Box, IconButton } from "@mui/material";
import AllRecentConnections from "./AllRecentConnections";
import SelectedSessions from "./SelectedSessions";
import SchemaDialog from "components/SchemaDialog";
import MySQLlogo from "assets/MySQLlogo.png";
import { useTheme } from "@emotion/react";
import { GlobalContext } from "context/GlobalContext";
import { LogoutSharp } from "@mui/icons-material";

const LeftSection = memo(() => {
  const theme = useTheme();
  const { hitAxios, authData, setAuthData, setUserData } =
    React.useContext(GlobalContext);

  const [connectionsExpanded, setConnectionsExpanded] = React.useState(true);
  const [sessionExpanded, setSessionExpanded] = React.useState(false);

  return (
    <div className={styles.leftSection}>
      <Box sx={{ height: "95%", gap: "10px", color: "white" }}>
        <div className={styles.leftHeader}>
          <Typography variant="h4">Chat-DB</Typography>
          {/* <span>MySQL</span> */}
          <IconButton
            onClick={() => {
              setAuthData({
                ...authData,
                isLoggedin: false,
                authToken: "",
              });
              setUserData({});
            }}
          >
            <LogoutSharp height={10} width={10}/>
          </IconButton>
        </div>
        <Box sx={{ height: "90%", overflow: "hidden" }}>
          <AllRecentConnections
            connectionsExpanded={connectionsExpanded}
            setConnectionsExpanded={setConnectionsExpanded}
            sessionExpanded={sessionExpanded}
          />
          <SelectedSessions
            sessionExpanded={sessionExpanded}
            setSessionExpanded={setSessionExpanded}
            connectionsExpanded={connectionsExpanded}
          />
        </Box>
      </Box>
      <Box
        sx={{
          padding: "0 0 4px 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "5px",
          height: "5%",
        }}
      >
        <Typography color="primary">Powered by</Typography>
        <img src={ulaiLogo} alt="Ulai.in" width={50} height={30} />
      </Box>
      <SchemaDialog />
    </div>
  );
});

export default LeftSection;
