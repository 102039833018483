import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import { useTheme } from "styled-components";
import { GlobalContext } from "context/GlobalContext";
import JsonDisplay from "./JsonDisplay";

const SchemaDialog = ({ open, onClose }) => {
  const { hitAxios, userData, setUserData } = React.useContext(GlobalContext);

  const theme = useTheme();
  const [selectedSchema, setSelectedSchema] = useState(null);

  const handleListItemClick = (schema) => {
    setSelectedSchema(schema);
  };

  return (
    <Dialog
      open={userData?.previewData !== undefined}
      onClose={() => setUserData({ ...userData, previewData: undefined })}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Schema Details</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid
            item
            xs={4}
            sx={{ height: theme.typography.pxToRem(480), overflowY: "auto" }}
          >
            <List>
              {userData?.previewData?.map((schema) => (
                <ListItemButton
                  button
                  key={schema?.TABLE_NAME}
                  selected={selectedSchema?.TABLE_NAME === schema?.TABLE_NAME}
                  onClick={() => handleListItemClick(schema)}
                >
                  <ListItemText primary={schema.TABLE_NAME} />
                </ListItemButton>
              ))}
            </List>
          </Grid>
          {/* <hr /> */}
          <Grid item xs={8} sx={{}}>
            {selectedSchema ? (
              <JsonDisplay data={selectedSchema.COLUMNS} />
            ) : (
              <Typography variant="body1">
                Select a schema to see details
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <Grid container justifyContent="flex-end" padding={2}>
        <Button
          onClick={() => setUserData({ ...userData, previewData: undefined })}
          variant="contained"
          sx={{ color: "primary.main", backgroundColor: "primary.dark" }}
        >
          Close
        </Button>
      </Grid>
    </Dialog>
  );
};

export default SchemaDialog;
