import axios from "axios";
import React from "react";

export const GlobalContext = React.createContext(null);

export const GlobalProvider = (props) => {
  const url = process.env.REACT_APP_BASE_URL;
  const [userData, setUserData] = React.useState({});
  const [authData, setAuthData] = React.useState({
    authToken: "",
    isLoggedin: false,
  });
  const [data, setData] = React.useState({
    loading: false,
    snackbarOpen: false,
    snackbarMessage: "",
  });

  async function hitAxios({ path, reqObj, requestType }) {
    setData({ ...data, loading: true });
    // if (!data?.authToken) callLogout();
    return new Promise(async (resolve, reject) => {
      try {
        let resp;
        if (requestType === "patch") {
          resp = await axios.patch(
            path.startsWith("http") ? path : url + path,
            reqObj,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authData?.authToken,
              },
            }
          );
        } else if (requestType === "del") {
          resp = await axios.delete(
            path.startsWith("http") ? path : url + path,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authData?.authToken,
              },
            }
          );
        } else if (requestType === "post") {
          resp = await axios.post(
            path.startsWith("http") ? path : url + path,
            reqObj,
            {
              headers:
                path === "/db-chat/chat/query"
                  ? {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + authData?.authToken,
                      "connection-id":
                        userData?.activeConnection["connection-id"],
                    }
                  : {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + authData?.authToken,
                    },
            }
          );
        } else {
          resp = await axios.get(path.startsWith("http") ? path : url + path, {
            headers: {
              Authorization: "Bearer " + authData?.authToken,
            },
          });
        }

        // if (resp?.data?.logout) {
        //   // localStorage.clear();
        // }

        if (resp?.data?.msg) {
          setData({
            ...data,
            loading: false,
            snackbarOpen: true,
            snackbarMessage: resp?.data?.msg || "Great",
            severity: "info",
          });
          setTimeout(() => {
            setData({
              ...data,
              loading: false,
              snackbarOpen: false,
              snackbarMessage: "",
              severity: "",
            });
          }, 6000);
        }

        resolve(resp);
      } catch (err) {
        if (err.response) {
          console.log(err.response.data);
          resolve(err?.response);
        }
        let resSnackMessage = "";
        if (err?.response?.data?.error === "Please authenticate") {
          setAuthData({
            ...authData,
            isLoggedin: false,
          });
          resSnackMessage = "User Authentication Failed Contact Admin.";
        } else {
          resSnackMessage =
            err?.response?.data?.message ??
            err?.response?.data?.error ??
            "Something Went Wrong";
        }
        setData({
          ...data,
          snackbarMessage: resSnackMessage,
          snackbarOpen: true,
          loading: false,
          severity: "error",
        });
        setTimeout(() => {
          setData({
            ...data,
            snackbarMessage: "",
            snackbarOpen: false,
            loading: false,
            severity: "",
          });
        }, 6000);
      }
    });
  }
  return (
    <GlobalContext.Provider
      value={{
        data,
        setData,
        hitAxios,
        userData,
        setUserData,
        authData,
        setAuthData,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};
