import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { createTheme } from "@mui/material";
import { GlobalProvider } from "context/GlobalContext";
import { ThemeProvider } from "styled-components";
// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById("root"));

const theme = createTheme({
  mode: "dark",
  typography: {
    pxToRem: (size) => `${size / 18.018}rem`,
    pxTovh: (size) => `${(size * 100) / 940}rem`,
    pxTovw: (size) => `${(size * 100) / 1920}rem`,
  },
  palette: {
    primary: {
      main: "#fff",
      dark: "#000",
      darkLite: "rgba(27, 27, 27)",
    },
  },
});

root.render(
  <React.StrictMode>
    <GlobalProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </GlobalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
