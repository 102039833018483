import React from "react";
import { Snackbar, Alert } from "@mui/material";
import { GlobalContext } from "context/GlobalContext";

const SnackbarComponent = () => {
  const { hitAxios, data, setData } = React.useContext(GlobalContext);
  // { open, handleClose, message }
  const handleClose = () => {
    setData({ ...data, snackbarOpen: false, snackbarMessage: undefined });
  };
  return (
    <Snackbar
      open={data.snackbarOpen}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={data.severity} sx={{ width: "100%" }}>
        {data.snackbarMessage ?? "Great !!"}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;
