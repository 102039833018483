import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { useTheme } from "@emotion/react";

const JsonDisplay = ({ data }) => {
  const theme = useTheme();
  const renderJson = (json, indentLevel = 0) => {
    if (typeof json !== "object" || json === null) {
      return <span>{JSON.stringify(json)}</span>;
    }

    const entries = Object.entries(json);

    return (
      <Box
        sx={{
          marginLeft: `${indentLevel * 20}px`,
        }}
      >
        {Array.isArray(json) ? "[" : "{"}
        {entries.map(([key, value], index) => (
          <div
            style={{
              marginLeft: `${indentLevel * 20}px`,
            }}
            key={index}
          >
            {!Array.isArray(json) && <strong>{key}: </strong>}
            {renderJson(value, indentLevel + 1)}
            {index < entries.length - 1 && !(key < Number.MAX_SAFE_INTEGER)  && ","}
          </div>
        ))}
        {Array.isArray(json) ? "]" : "},"}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        padding: theme.typography.pxToRem(20),
        backgroundColor: "rgb(0 0 0 / 70%)",
        borderRadius: theme.typography.pxToRem(20),
        fontFamily: "monospace",
      }}
    >
      <Box sx={{ height: theme.typography.pxToRem(420), overflowY: "auto" }}>
        {renderJson(data)}
      </Box>
    </Box>
  );
};

JsonDisplay.propTypes = {
  data: PropTypes.any.isRequired,
};

export default JsonDisplay;
