import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import LeftSection from "components/LeftSection";
import RightSection from "components/RightSection";
import styles from "styles/page.module.css";
import { GlobalContext, GlobalProvider } from "context/GlobalContext";
import HomePageMain from "HomePageMain";
import SnackbarComponent from "components/SnackBar.jsx";

function App() {
  const theme = createTheme({
    mode: "dark",
    typography: {
      pxToRem: (size) => `${size / 18.018}rem`,
      pxTovh: (size) => `${(size * 100) / 940}rem`,
      pxTovw: (size) => `${(size * 100) / 1920}rem`,
    },
    palette: {
      primary: {
        main: "#fff",
        dark: "#000000f2",
        darkLite: "rgba(27, 27, 27)",
      },
      mode: 'dark',
      // primary: {
      //   main: '#1976d2',
      // },
      background: {
        default: '#303030',
        paper: '#424242',
      },
      text: {
        primary: '#ffffff',
      },
    },
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: '#ffffff',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#ffffff',
            },
          },
        },
      },
    },
  });
  
  return (
    <GlobalProvider>
      <ThemeProvider theme={theme}>
        <HomePageMain />
        <SnackbarComponent />
      </ThemeProvider>
    </GlobalProvider>
  );
}

export default App;
