import React, { useRef } from "react";
import {
  AccountTreeOutlined,
  AddCircleOutlined,
  ExpandMore,
  Visibility,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Slide,
  Typography,
} from "@mui/material";
import DatabaseForm from "./DataBaseForm";
import { GlobalContext } from "context/GlobalContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const AllRecentConnections = ({
  connectionsExpanded,
  setConnectionsExpanded,
  sessionExpanded,
}) => {
  const { hitAxios, userData, setUserData, data, setData } =
    React.useContext(GlobalContext);

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const isInitialMount = useRef(true);

  const changeConnection = async (obj) => {
    // here we need to add one more loader in chat connections
    const res = await hitAxios({
      path: "/db-chat/connections/getallsessions",
      requestType: "post",
      reqObj: {
        connectionId: obj
          ? obj["connection-id"]
          : userData?.activeConnection["connection-id"],
      },
    });
    if (res?.status === 200) {
      setUserData({
        ...userData,
        allSessions: res?.data?.data?.map((i) => ({
          id: i,
          active: false,
        })),
        // Setting active connect on button click
        activeConnection: obj,
        allconnections: userData?.allconnections.map((i) => ({
          ...i,
          active: i === obj,
        })),
        activeConversation: [],
        activeSession: undefined,
      });
    }
  };
  const getAllconnections = async () => {
    const res = await hitAxios({
      path: "/db-chat/connections/getallconnections",
      requestType: "get",
    });
    if (res.status === 200) {
      setUserData({
        ...userData,
        allconnections: res?.data?.data?.map((i) => ({
          "connection-id": i["connection-id"],
          tables: i?.schema,
          displayName: i.db,
          active: false,
        })),
        activeConversation: [],
      });
    }
  };

  React.useEffect(() => {
    if (isInitialMount.current) {
      getAllconnections();
      isInitialMount.current = false;
    }
  }, []);

  return (
    <Accordion
      disableGutters
      sx={{
        backgroundColor: "rgb(42 44 44)",
        color: "white",
        "& .MuiAccordion-root .Mui-expanded": { margin: "10px 0" },
        
      }}
      expanded={connectionsExpanded}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMore
            color="primary"
            onClick={() => {
              setConnectionsExpanded(!connectionsExpanded);
            }}
          />
        }
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography sx={{ width: "88%", flexShrink: 0 }}>
          Recent Connections
        </Typography>
        <AddCircleOutlined
          onClick={() => {
            // Open PopUp for Add Connections
            setDialogOpen(true);
          }}
        />
        <DatabaseForm
          open={dialogOpen}
          setOpen={setDialogOpen}
          changeConnection={changeConnection}
        />
      </AccordionSummary>
      <AccordionDetails
        sx={{ overflowY: "auto", height: sessionExpanded ? "32vh" : "64vh" }}
      >
        {userData?.allconnections?.length ? (
          userData?.allconnections?.map((obj) => (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: obj?.active
                  ? "rgba(255, 255, 255, 0.5)"
                  : "none",
                alignItems: "center",
                borderRadius: "4px",
                ":hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                },
              }}
            >
              <Button
                onClick={() => changeConnection(obj)}
                sx={{
                  justifyContent: "flex-start",
                }}
                fullWidth
                disableRipple
              >
                <AccountTreeOutlined />
                <Typography
                  color="primary"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textTransform: "None",
                    paddingLeft: (theme) => theme.typography.pxToRem(4),
                  }}
                >
                  {obj?.displayName}
                </Typography>
              </Button>
              <Visibility
                sx={{ padding: "6px" }}
                onClick={() => {
                  if (obj?.tables)
                    setUserData({
                      ...userData,
                      previewData: obj.tables,
                    });
                  else {
                    setData({
                      ...data,
                      snackbarOpen: true,
                      snackbarMessage: "No Schema available for preview",
                      severity: "warning",
                    });
                  }
                }}
              />
            </Box>
          ))
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography>No Connections</Typography>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default AllRecentConnections;
