import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Tabs,
  Tab,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { GlobalContext } from "context/GlobalContext";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Authenticate = () => {
  const { hitAxios, authData, setAuthData, data, setData } =
    React.useContext(GlobalContext);
  const [tab, setTab] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (event, newValue) => {
    setTab(newValue);
    setFormData({
      username: "",
      password: "",
      confirmPassword: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (tab === 1) {
      const res = await hitAxios({
        path: "/db-chat/user/signup",
        requestType: "post",
        reqObj: formData,
      });
      if (
        res?.status === 201 &&
        res?.data?.message === "User created successfully"
      ) {
        setTab(0);
        setData({
          ...data,
          snackbarOpen: true,
          snackbarMessage: res?.data?.message,
          severity: "success"
        });
        setFormData({
          username: "",
          password: "",
          confirmPassword: "",
        });
      } else if (res?.data?.message === "Username already exists") {
        //  Handle snackbar
      } else {
        setData({
          ...data,
          snackbarOpen: true,
          snackbarMessage: "Error Creating User, Contact Admin.",
          severity: "error"
        });
      }
    } else {
      const res = await hitAxios({
        path: "/db-chat/user/login",
        requestType: "post",
        reqObj: formData,
      });
      if (res?.status === 200 && res?.data?.token) {
        setData({
          ...data,
          snackbarOpen: true,
          snackbarMessage: "Create connection and chat!!!",
          severity: "info"
        });
        setAuthData({
          ...authData,
          isLoggedin: true,
          authToken: res?.data?.token,
        });
        setFormData({
          username: "",
          password: "",
          confirmPassword: "",
        });
      }
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 4,
        maxWidth: 400,
        margin: "auto",
        backgroundColor: "#424242",
      }}
    >
      <Tabs value={tab} onChange={handleChange} centered>
        <Tab label="Login" />
        <Tab label="Signup" />
      </Tabs>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
        <TextField
          label="username"
          name="username"
          value={formData.username}
          onChange={handleInputChange}
          fullWidth 
          required
          margin="normal"
          variant="outlined"
          size="small"
          InputLabelProps={{ style: { color: "#ffffff" } }}
          InputProps={{ style: { color: "#ffffff" } }}
        />
        <TextField
          label="Password"
          name="password"
          type={showPassword ? "text" : "password"}
          value={formData.password}
          onChange={handleInputChange}
          margin="normal"
          variant="outlined"
          InputLabelProps={{ style: { color: "#ffffff" } }}
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth
          required
        />
        {tab === 1 && (
          <TextField
            label="Confirm Password"
            name="confirmPassword"
            type={showConfirmPassword ? "text" : "password"}
            value={formData.confirmPassword}
            onChange={handleInputChange}
            fullWidth
            required
            size="small"
            margin="normal"
            variant="outlined"
            InputLabelProps={{ style: { color: "#ffffff" } }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2, color: "primary.main", backgroundColor: "primary.dark" }}
        >
          {tab === 0 ? "Login" : "Signup"}
        </Button>
      </Box>
    </Paper>
  );
};

export default Authenticate;
