import React from "react";
import Draggable from "react-draggable";
import { CloseFullscreen, DragIndicatorOutlined } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import CreateTable from "components/MUITable/CreateTable";

const BottomDragableArea = ({
  previewData,
  isPreviewOpen,
  setIsPreviewOpen,
}) => {
  const [position, setPosition] = React.useState({ x: 0, y: 0 });

  const handleDrag = (e, data) => {
    console.log(data, e);
    if (data.y < 666 && data.y > 0) setPosition({ x: 0, y: data.y });
  };

  React.useEffect(() => {
    if (isPreviewOpen) setPosition({ x: 0, y: 0 });
    else setPosition({ x: 0, y: "100vh" });
  }, [isPreviewOpen]);
  return (
    <Draggable
      axis="y"
      disabled={!isPreviewOpen}
      position={position}
      onDrag={handleDrag}
    >
      <Grid
        sx={{
          height: "100%",
          position: "relative",
          top: isPreviewOpen ? "-97%" : "0%",
          transition: "all .3s ease-out",
          display: "flex",
          justifyContent: "center",
          color: "white",
          flexDirection: "column",
          background: "rgba(255, 255, 255, 0.2)",
          borderRadius: "8px 8px 0 0",
          boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
          border: "1px solid rgba(255, 255, 255, 0.3)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            borderRadius: "8px 8px 0 0",
            justifyContent: "center",
            backgroundColor: "black",
          }}
          onClick={() => setIsPreviewOpen(false)}
        >
          {isPreviewOpen ? (
            <CloseFullscreen />
          ) : (
            <DragIndicatorOutlined sx={{ rotate: "90deg" }} />
          )}
        </Box>
        <Box sx={{ height: "100%" }}>
          <CreateTable
            previewData={previewData}
            columnsList={Object.keys(previewData[0] ?? {})}
          />
        </Box>
      </Grid>
    </Draggable>
  );
};

export default BottomDragableArea;
