import React from "react";
import LeftSection from "components/LeftSection";
import RightSection from "components/RightSection";
import styles from "styles/page.module.css";
import { GlobalContext } from "context/GlobalContext";
import Authenticate from "Auth/Authenticate";

function HomePageMain() {
  const { authData } = React.useContext(GlobalContext);

  return (
    <div className={styles.mainpage}>
      {authData.isLoggedin ? (
        <>
          <div className={styles.leftOut}>
            <LeftSection />
          </div>
          <div className={styles.rightOut}>
            <RightSection />
          </div>
        </>
      ) : (
        <Authenticate />
      )}
    </div>
  );
}

export default HomePageMain;
