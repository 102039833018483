import React, { Component, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid,
  ThemeProvider,
  createTheme,
  IconButton,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { GlobalContext } from "context/GlobalContext";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#90caf9",
    },
  },
});

function DatabaseDialog({ open, setOpen }) {
  const { hitAxios, userData, setUserData } = React.useContext(GlobalContext);
  const [formValues, setFormValues] = useState({
    db: "",
    host: "",
    port: "",
    user: "",
    password: "",
    dialect: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    const res = await hitAxios({
      path: "/db-chat/db/connectandcheck",
      requestType: "post",
      reqObj: formValues,
    });
    if (res.data.connectionId) {
      setUserData({
        ...userData,
        activeConnection: {
          "connection-id": res?.data?.connectionId,
          displayName: formValues.db,
          tables: res?.data?.tables,
        },
        allconnections: [
          {
            "connection-id": res?.data?.connectionId,
            displayName: formValues.db,
            tables: res?.data?.tables,
            active: true,
          },
          ...userData?.allconnections.map((i) => ({
            ...i,
            active: false,
          })),
        ],
        activeConversation: [],
        activeSession: undefined,
      });
      setOpen(false);
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open Database Configuration Dialog
      </Button> */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Database Configuration</DialogTitle>
        <DialogContent>
          <DialogContentText gutterBottom>
            Please enter the database configuration details to add a
            connections.
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Database"
                variant="outlined"
                name="db"
                value={formValues.db}
                onChange={handleInputChange}
                size="small"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Host"
                variant="outlined"
                name="host"
                value={formValues.host}
                onChange={handleInputChange}
                size="small"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Port"
                variant="outlined"
                name="port"
                value={formValues.port}
                onChange={handleInputChange}
                size="small"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="User"
                variant="outlined"
                name="user"
                value={formValues.user}
                onChange={handleInputChange}
                size="small"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Password"
                variant="outlined"
                name="password"
                type={showPassword ? "text" : "password"}
                value={formValues.password}
                onChange={handleInputChange}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth  size="small" >
                <InputLabel id="demo-simple-select-label">Dialect</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formValues.dialect}
                  label="Dialect"
                  name="dialect"
                  onChange={handleInputChange}
                  fullWidth
                  required
                  sx={{
                    color: "white",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#ffffff",
                    },
                    "& .MuiSelect-icon": {
                      color: "#ffffff",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#ffffff",
                    },
                  }}
                  MenuProps={{
                    sx: {
                      "& .Mui-selected": {
                        backgroundColor: "grey",
                      },
                    },
                  }}
                  inputProps={{ style: { borderBottom: "white" } }}
                >
                  <MenuItem value="mysql">mysql</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            disabled={
              formValues.db === "" ||
              formValues.host === "" ||
              formValues.port === "" ||
              formValues.user === "" ||
              formValues.password === "" ||
              formValues.dialect === ""
            }
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default DatabaseDialog;
